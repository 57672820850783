export const pressArticlesValidator = (articles) => {
  if (articles === []) return true
  let isArticleFormatWrong = true
  articles.forEach(({ image, publicationDate, text, link }) => {
    if (
      image === undefined &&
      publicationDate === undefined &&
      text === undefined &&
      link === undefined
    ) {
      isArticleFormatWrong = false
    }
  })

  return isArticleFormatWrong
}
