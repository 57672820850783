
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCard } from '@ds/components/Card'
import isEmpty from 'lodash/isEmpty'

import ImageRender from '@cms/components/private/ImageRender/ImageRender.vue'
import { imageValidator } from '@cms/helpers/image'

export default {
  components: {
    RevButtonBase,
    ImageRender,
    RevCard,
  },
  props: {
    tracking: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    publicationDate: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      validator: (image) => {
        if (isEmpty(image)) return true

        return imageValidator(image)
      },
      default: () => ({}),
    },
    link: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
  computed: {
    hasLink() {
      return !isEmpty(this.link)
    },
    hasImage() {
      return !isEmpty(this.image)
    },
    getTarget() {
      return this.hasLink && this.link.targetBlank ? '_blank' : '_self'
    },
  },
}
