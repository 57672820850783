
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

import { COUNTRIES } from '@config/constants'
import { ROUTES } from '@router'

import translations from '../../helpers/translations/cardSlider.translations'
import { ArticleCard } from '../private/ArticleCard'
import BlockTitle from '../private/BlockTitle'

import translationsPressCarousel from './PressCarousel.translations'
import { pressArticlesValidator } from './PressCarousel.validators'

export default {
  components: {
    RevButtonTiny,
    BlockTitle,
    RevCardCarousel,
    ArticleCard,
  },
  props: {
    articles: {
      type: Array,
      default: () => [],
      validator: pressArticlesValidator,
    },
    subtitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    translations: () => ({
      ...translations,
      ...translationsPressCarousel,
    }),
    ...mapGetters({
      country: 'config/country',
    }),
    pressLink() {
      return this.PRESS_LINKS_BY_COUNTRY[this.country] || {}
    },
    hasArticles() {
      return !isEmpty(this.articles)
    },
    hasPressLink() {
      return !isEmpty(this.pressLink)
    },
    hasTitle() {
      return !isEmpty(this.title)
    },
    isHeadingVisible() {
      return this.hasPressLink || this.hasTitle
    },
  },
  created() {
    this.PRESS_LINKS_BY_COUNTRY = {
      // The press page exists and is linked from the carousel only in Japan
      [COUNTRIES.JP]: {
        name: ROUTES.CMS.EVENT,
        params: { pageName: 'press' },
      },
    }
  },
  methods: {
    // TODO: Should be updated as part of GNL-2858
    tracking({ position = '', slideIndex }) {
      return {
        name: [slideIndex, position].join('-'),
      }
    },
    getLoadingAttribute(index) {
      return index === 0 ? 'eager' : 'lazy'
    },
  },
}
