export default {
  prevSlideText: {
    id: 'cms_products_slider_prev_slide',
    defaultMessage: 'Display previous product',
  },
  nextSlideText: {
    id: 'cms_products_slider_next_slide',
    defaultMessage: 'Display next product',
  },
}
